import type {
  BudgetDocument,
  TransactionDocument,
  ItemDocument,
  Platform,
} from '@og-shared/types';
import {
  getTodayString,
  getToday,
  BudgetType,
  INCOME_BUDGET_ID,
  calcFrequencyFromBudget,
  arrayToDictionary,
} from '@og-shared/utils';
import { ScriptableLineSegmentContext } from 'chart.js';

import { translate } from './translate';

export function calcCashFlowForBudgets(
  budgets:
    | Pick<
        BudgetDocument,
        | 'per_year'
        | 'budget'
        | 'funding_amount'
        | 'date_start'
        | 'date_end'
        | 'type'
        | 'funding_per_year'
        | 'is_envelope'
      >[],
  frequency: BudgetFrequencyView | 365,
  todayString: string
) {
  return budgets.reduce(
    (val, budget) =>
      calcFrequencyFromBudget({ budget, frequency, todayString }) *
        (budget.type === BudgetType.INCOME ? 1 : -1) +
      val,
    0
  );
}

export function isValidEmail(email?: string) {
  if (!email) return false;
  return /^.+@.+\..+$/.test(email);
}

export function calcPercentage(available: number, budget: number) {
  const percentage = 100 * (available / budget);
  if (percentage > 100) {
    return 100;
  } else if (percentage < 0) {
    return 0;
  } else {
    return percentage;
  }
}

export function removeZeroSplits(transaction: TransactionDocument) {
  const budgets = transaction.budgets.filter(split => split.amount);
  const budget_ids: { [budget_id: string]: string } = {};
  budgets.map(budget => {
    if (budget.budget_id) {
      budget_ids[budget.budget_id] = transaction.date;
    }
    if (budget.child_budget_id) {
      budget_ids[budget.child_budget_id] = transaction.date;
    }
  });
  const removeSplits: TransactionDocument = {
    ...transaction,
    budgets,
    budget_ids,
  };
  return removeSplits;
}

export function itemUpdatedLessADayAgo(item: ItemDocument | undefined) {
  if (!item) return false;
  const now = new Date().getTime();
  const oneDayAgo = now - 1000 * 60 * 60 * 24;
  return item && item.last_webhook && item.last_webhook.toMillis() > oneDayAgo;
}

function itemLastUpdatedMoreThanWeekAgo(item: ItemDocument) {
  const now = new Date().getTime();
  const oneWeekAgo = now - 1000 * 60 * 60 * 24 * 7;
  return item && item.last_webhook && item.last_webhook.toMillis() < oneWeekAgo;
}

export function getStatusColor(item: ItemDocument) {
  if (itemUpdatedLessADayAgo(item)) {
    // probably OK if account was updated in the last 24 hours
    return 'success';
  }
  if (itemLastUpdatedMoreThanWeekAgo(item)) {
    // it's been over a week since last update
    return 'warning';
  }
  if (item.type === 'asa') {
    return 'success'; // @ASA - how do we know their account still has a healthy connection?
  }
  switch (item.transactions_updates) {
    case 'DEGRADED':
      return 'warning';
    case 'DOWN':
      return 'danger';
    case 'HEALTHY':
      return 'success';
  }
}

export function copyObject<T>(object: T) {
  return JSON.parse(JSON.stringify(object)) as T;
}

export function waitFor(ms: number) {
  return new Promise(resolve => setTimeout(() => resolve(null), ms));
}

export function convertVersionToNumber(version: string) {
  const semVerArray = version.split('.');
  const addZeros = semVerArray.map(number => {
    if (number.length == 1) {
      return `00${number}`;
    } else if (number.length == 2) {
      return `0${number}`;
    } else {
      return number;
    }
  });
  const mergeArray = addZeros.join('');
  return Number(mergeArray);
}

function getDefaultTransactionDate(budget: BudgetDocument) {
  if (
    (budget.type === BudgetType.BILL || budget.type === BudgetType.INCOME) &&
    budget.next_transaction_date &&
    budget.budget_id !== INCOME_BUDGET_ID
  ) {
    return budget.next_transaction_date;
  } else {
    return getTodayString();
  }
}

function getDefaultTransactionAmount(budget: BudgetDocument) {
  if (
    (budget.type === BudgetType.BILL || budget.type === BudgetType.INCOME) &&
    budget.next_transaction_date
  ) {
    return budget.type === BudgetType.BILL ? -budget.budget : budget.budget;
  } else {
    return 0;
  }
}

export function getDefaultBudgetTransaction(budget: BudgetDocument) {
  const budget_id =
    budget.type === BudgetType.INCOME ? INCOME_BUDGET_ID : budget.budget_id;
  const child_budget_id =
    budget.type === BudgetType.INCOME ? budget.budget_id : null;
  const date = getDefaultTransactionDate(budget);
  const amount = getDefaultTransactionAmount(budget);
  const transaction: Pick<
    TransactionDocument,
    | 'date'
    | 'name'
    | 'amount'
    | 'budget_ids'
    | 'type'
    | 'budgets'
    | 'account_id'
  > = {
    date,
    name: budget.name,
    amount,
    type: 'manual_entry',
    budget_ids: { [budget_id]: date },
    budgets: [
      {
        budget_id,
        child_budget_id,
        amount,
      },
    ],
    account_id: null,
  };
  if (budget.account_id) {
    transaction.account_id = budget.account_id;
  }
  if (child_budget_id) {
    transaction.budget_ids[child_budget_id] = date;
  }
  return transaction;
}

export function getStartEndDatesAndAccountIdsFromTransactions(
  transactions: Pick<TransactionDocument, 'date' | 'account_id'>[]
) {
  let startDate = '9999-99-99';
  let endDate = '0000-00-00';
  const accountIds: string[] = [];
  transactions.map(t => {
    if (t.date < startDate) {
      startDate = t.date;
    }
    if (t.date > endDate) {
      endDate = t.date;
    }
    if (t.account_id && !accountIds.includes(t.account_id)) {
      accountIds.push(t.account_id);
    }
  });
  return {
    accountIds,
    startDate,
    endDate,
  };
}

export function getIncomeHealthTime(weeks: number): string {
  if (weeks < 0) {
    return `-${getIncomeHealthTime(weeks * -1)}`;
  }
  if (weeks < 5) {
    return translate('SMART_COUNT_WEEK', weeks);
  } else if (weeks < 52) {
    const months = Number((weeks / (52 / 12)).toFixed(0));
    return translate('SMART_COUNT_MONTH', months);
  } else {
    const years = Number((weeks / 52).toFixed(0));
    const leftoverWeeks = weeks % 52;
    if (leftoverWeeks) {
      return translate('YEARS_MONTHS', {
        years: translate('SMART_COUNT_YEAR', years),
        months: getIncomeHealthTime(leftoverWeeks),
      });
    } else {
      return translate('SMART_COUNT_YEAR', years);
    }
  }
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result: string = reader.result as string;
      const base64Data = result.split('base64,')[1];
      resolve(base64Data ?? '');
    };
    reader.readAsDataURL(blob);
  });
}

export function getPlatformLogo(platform: Platform) {
  const logoMap: { [key in Platform]: string } = {
    android: 'logo-android',
    ios: 'logo-apple',
    web: 'logo-pwa',
  };
  return logoMap[platform];
}

export function getPercentage(
  numerator: number,
  denominator: number,
  decimals = 2
) {
  const value = (numerator / denominator) * 100;
  if (isNaN(value)) {
    return '0%';
  } else {
    return value.toFixed(decimals) + '%';
  }
}

export function getBudgetsByIdFromBudgets(budgets: BudgetDocument[]) {
  const budgetsById = arrayToDictionary(budgets, 'budget_id');
  if (budgetsById[INCOME_BUDGET_ID]) {
    budgetsById[INCOME_BUDGET_ID].name = translate('INCOME_SAVED');
    budgetsById[INCOME_BUDGET_ID].is_envelope = true;
  }
  return budgetsById;
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function canLinkAccounts(params: { plaid: boolean; asa: boolean }) {
  const { plaid, asa } = params;
  return plaid || asa;
}

export const isFuture = (ctx: ScriptableLineSegmentContext) => {
  // must got back two days from today - so that the segment is dashed
  const today = getToday().getTime() - 48 * 60 * 60 * 1000;
  const future = ctx.p0.parsed.x > today;
  return future;
};

export function getGroupIdFromURL(url: string) {
  const urlArray = url.split('/');
  const indexOfGroups = urlArray.indexOf('groups');
  const groupId = urlArray[indexOfGroups + 1];
  return groupId;
}

export function isTouchScreen() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  );
}
