import {
  UntrackedBudgetId,
  BudgetDocument,
  BudgetFrequency,
  OptionalField,
} from '@og-shared/types';
import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import {
  exhaustiveCheck,
  isKeyPresent,
  isPresent,
  currencyNumber,
  countFundingDatesBetweenDates,
  getDateAtMidnight,
  getDateString,
  getNextDate,
  getTodayString,
} from '../utils';

import {
  IGNORE_BUDGET_ID,
  BudgetType,
  UNCATEGORIZED_BUDGET_ID,
} from './consts';
import { formatDate, getNextFundingDate } from './date-utils';

export function balanceNeededToReachBudget(
  budget: Pick<
    BudgetDocument,
    | 'next_transaction_date'
    | 'budget'
    | 'funding_amount'
    | 'funding_per_year'
    | 'funding_days'
  > & {
    next_transaction_date: string;
  }
) {
  const fundingDaysBeforeGoalDate = countFundingDatesBetweenDates(budget);

  const balanceShouldBe =
    budget.budget - fundingDaysBeforeGoalDate * budget.funding_amount;
  // console.log(
  //   'there are ',
  //   fundingDaysBeforeGoalDate,
  //   'funding days before ',
  //   budget.next_transaction_date,
  //   'to save up',
  //   budget.budget,
  //   '. your balance should be: ',
  //   balanceShouldBe
  // );
  return currencyNumber(balanceShouldBe);
}

export function calcFundingAmount(
  budget: Pick<
    BudgetDocument,
    | 'per_year'
    | 'next_transaction_date'
    | 'date_start'
    | 'date_end'
    | 'budget'
    | 'available'
    | 'is_envelope'
    | 'funding_amount'
    | 'funding_days'
    | 'funding_per_year'
    | 'type'
  >
) {
  // only used in budget.ts reducer when editing a budget
  // to calculate the required weekly fill
  const perYear = budget.per_year;

  const todayString = getTodayString();
  if (
    perYear === 0 &&
    budget.funding_per_year &&
    isKeyPresent(budget, 'next_transaction_date')
  ) {
    if (todayString > budget.next_transaction_date) {
      // already happened - no funding
      return 0;
    }
    const needed = budget.budget - budget.available;
    // console.log('use funding_per_year from budget', budget.funding_per_year);

    const numberOfFundingDays = countFundingDatesBetweenDates(budget);

    const fundingAmount = currencyNumber(needed / numberOfFundingDays);
    return fundingAmount;
  } else if (budget.budget && budget.funding_per_year) {
    const fundingAmount = calcFrequencyFromBudget({
      budget,
      frequency: budget.funding_per_year,
      todayString,
    });
    return fundingAmount;
  } else {
    return 0;
  }
}

export function calcFrequencyFromBudget(params: {
  budget: Pick<
    BudgetDocument,
    | 'per_year'
    | 'budget'
    | 'date_end'
    | 'type'
    | 'next_transaction_date'
    | 'funding_per_year'
    | 'is_envelope'
    | 'funding_amount'
  >;
  frequency: BudgetFrequency | 365;
  todayString: string;
}) {
  const { budget: budgetDoc, frequency, todayString } = params;
  if (!budgetDoc) return 0;
  const {
    budget = 0,
    per_year = 0,
    is_envelope = false,
    funding_amount = 0,
    funding_per_year = 0,
    type,
  } = budgetDoc;
  if (!budget || !per_year) {
    if (is_envelope) {
      const res = funding_amount * (funding_per_year / frequency);
      return res;
    }
    // if not an envelope just assume that once means once per year
    return roundUpDown(budget * (1 / frequency), type);
  }

  const includeInCashFlow =
    shouldIncludeBudgetTransactionBasedOnStartAndEndDates(
      budgetDoc,
      todayString
    );
  if (!includeInCashFlow) {
    // console.log("don't include in cash flow", budget);
    return 0;
  }
  if (frequency === per_year) {
    return budget;
  }
  const notRounded = budget * (per_year / frequency);
  return roundUpDown(notRounded, type);
}

function roundUpDown(notRounded: number, type: BudgetType) {
  if (type === BudgetType.INCOME) {
    const roundDown = Math.floor(notRounded);
    return roundDown;
  } else {
    const roundedUp = Math.ceil(notRounded);
    return roundedUp;
  }
}

export function shouldIncludeBudgetTransactionBasedOnStartAndEndDates(
  budget: Pick<BudgetDocument, 'date_start' | 'date_end'>,
  date: string
) {
  if (budget.date_start && date < budget.date_start) {
    // don't include if today is before start date
    return false;
  }
  if (budget.date_end && date > budget.date_end) {
    // don't include if today is after the end date
    return false;
  }
  return true;
}

export function getAllBudgetFrequencies() {
  const arrayOfAllFrequencies = <T extends BudgetFrequency[]>(
    array: T & ([BudgetFrequency] extends [T[number]] ? unknown : 'Invalid')
  ) => array;

  // const missing = arrayOfAllFrequencies([1, 2]); // error
  // const extra = arrayOfAllFrequencies([0, 1, 2, 4, 6, 12, 24, 26, 52, 365]); // error
  return arrayOfAllFrequencies([0, 1, 2, 4, 6, 12, 24, 26, 52]); // compiles
}

export function sortBudgetsFromPlan(
  a: Pick<
    BudgetDocument,
    | 'budget'
    | 'date_end'
    | 'date_start'
    | 'funding_amount'
    | 'funding_per_year'
    | 'is_envelope'
    | 'per_year'
    | 'type'
  >,
  b: Pick<
    BudgetDocument,
    | 'budget'
    | 'date_end'
    | 'date_start'
    | 'funding_amount'
    | 'funding_per_year'
    | 'is_envelope'
    | 'per_year'
    | 'type'
  >
) {
  const todayString = getTodayString();
  return (
    calcFrequencyFromBudget({
      budget: b,
      frequency: 1,
      todayString,
    }) -
    calcFrequencyFromBudget({
      budget: a,
      frequency: 1,
      todayString,
    })
  );
}

export function sortBudgetsByDueDate(
  a: Pick<
    BudgetDocument,
    | 'budget'
    | 'date_end'
    | 'date_start'
    | 'funding_amount'
    | 'funding_per_year'
    | 'is_envelope'
    | 'next_transaction_date'
    | 'per_year'
    | 'type'
  >,
  b: Pick<
    BudgetDocument,
    | 'budget'
    | 'date_end'
    | 'date_start'
    | 'funding_amount'
    | 'funding_per_year'
    | 'is_envelope'
    | 'next_transaction_date'
    | 'per_year'
    | 'type'
  >
) {
  if (
    a.next_transaction_date === b.next_transaction_date ||
    (!a.next_transaction_date && !b.next_transaction_date)
  ) {
    return sortBudgetsFromPlan(a, b);
  }
  if (!a.next_transaction_date) {
    return 1;
  }
  if (!b.next_transaction_date) {
    return -1;
  }
  return a.next_transaction_date > b.next_transaction_date ? 1 : -1;
}

export function budgetNotTracked(budget_id: string) {
  const notTracked: UntrackedBudgetId[] = [
    IGNORE_BUDGET_ID,
    UNCATEGORIZED_BUDGET_ID,
  ];
  return notTracked.indexOf(budget_id as any) !== -1;
}

export function budgetIsTracked(budgetId: string) {
  const notTracked: UntrackedBudgetId[] = [
    IGNORE_BUDGET_ID,
    UNCATEGORIZED_BUDGET_ID,
  ];
  return notTracked.indexOf(budgetId as any) === -1;
}

export function calcDateGoalAchieved(
  budgetDoc: Pick<
    BudgetDocument,
    | 'available'
    | 'budget'
    | 'date_start'
    | 'funding_amount'
    | 'funding_days'
    | 'funding_per_year'
  >
): string | null {
  const {
    available,
    budget,
    date_start,
    funding_amount,
    funding_days,
    funding_per_year,
  } = budgetDoc;
  if (funding_amount === 0 || !isPresent(funding_amount)) {
    return null;
  }
  if (funding_per_year === 0 || !isPresent(funding_per_year)) {
    // no funding schedule or one time funding
    // return next funding date because it'll fund all at once or never
    return (
      getNextFundingDate(
        {
          funding_per_year,
          funding_days,
        },
        getTodayString()
      ) ?? null
    );
  }

  const todayString = getTodayString();
  const amountNeeded = budget - available;
  if (amountNeeded <= 0) {
    return todayString;
  }
  let fundingDaysNeeded = Math.ceil(amountNeeded / funding_amount);

  let startDateString =
    date_start && date_start > todayString ? date_start : todayString;

  const nextFundingDate = getNextFundingDate(
    {
      funding_days,
      funding_per_year,
    },
    startDateString
  );

  // console.log('calc needed', {
  //   fundingDaysNeeded,
  //   amountNeeded,
  //   funding_amount,
  //   nextFundingDate,
  //   today: todayString,
  // });
  // if (amountNeeded <= funding_amount && isPresent(nextFundingDate)) {
  //   return nextFundingDate;
  // }
  if (nextFundingDate && nextFundingDate > startDateString) {
    // because we're starting from the first funding date
    fundingDaysNeeded -= 1;
    startDateString = nextFundingDate;
  }
  if (nextFundingDate && nextFundingDate < startDateString) {
    // already past
    startDateString = nextFundingDate;
  }
  const startDate = getDateAtMidnight(startDateString);

  switch (funding_per_year) {
    case 1: {
      return getDateString(addYears(startDate, fundingDaysNeeded));
    }
    case 2: {
      return getDateString(addMonths(startDate, fundingDaysNeeded * 6));
    }
    case 4: {
      return getDateString(addMonths(startDate, fundingDaysNeeded * 3));
    }
    case 6: {
      return getDateString(addMonths(startDate, fundingDaysNeeded * 2));
    }
    case 12: {
      return getDateString(addMonths(startDate, fundingDaysNeeded));
    }
    case 24: {
      if (fundingDaysNeeded % 2 === 0) {
        // then it's monthly
        return getDateString(addMonths(startDate, fundingDaysNeeded / 2));
      }
      const date = getDateString(
        addMonths(startDate, (fundingDaysNeeded - 1) / 2)
      );
      return getNextDate({
        dateString: date,
        perYear: 24,
        days: funding_days,
      });
    }
    case 26: {
      return getDateString(addWeeks(startDate, fundingDaysNeeded * 2));
    }
    case 52: {
      return getDateString(addWeeks(startDate, fundingDaysNeeded));
    }
    default: {
      exhaustiveCheck(funding_per_year);
      return startDateString;
    }
  }
}

export function calcExpectedInBudgets(
  budgets: Pick<BudgetDocument, 'expected_balance'>[]
) {
  const expectedInBudgets = budgets.reduce(
    (prev, curr) => (curr.expected_balance ?? 0) + prev,
    0
  );
  return expectedInBudgets;
}

export const setEditBudgetIsEnvelope = (
  editBudget: OptionalField<BudgetDocument, 'budget_id'>,
  isEnvelope: boolean
): Pick<
  BudgetDocument,
  'funding_amount' | 'funding_days' | 'funding_per_year' | 'is_envelope'
> => {
  const funding_days = editBudget.transaction_days;
  const funding_per_year = editBudget.per_year;
  if (isEnvelope) {
    const funding_amount = calcFundingAmount({
      ...editBudget,
      funding_days,
      funding_per_year,
    });
    return {
      funding_amount,
      funding_days,
      funding_per_year,
      is_envelope: true,
    };
  } else {
    return {
      funding_amount: 0,
      funding_days,
      funding_per_year: 0,
      is_envelope: false,
    };
  }
};

export function shouldFundBudgetToday(
  budget: Pick<
    BudgetDocument,
    'type' | 'funding_amount' | 'date_end' | 'funding_per_year' | 'funding_days'
  >,
  today: string
) {
  const yesterday = formatDate(addDays(getDateAtMidnight(today), -1));
  const nextFundingDate = getNextFundingDate(budget, yesterday);
  return nextFundingDate === today;
}
