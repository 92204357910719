import { AccountDocument, BudgetDocument } from '@og-shared/types';
import { currencyNumber, INCOME_BUDGET_ID } from '@og-shared/utils';

export function getAccountsVsBudget(params: {
  budgets: BudgetDocument[];
  totalAccountsAvailable: BudgetsState['accounts']['total_accounts_available'];
}) {
  const { budgets, totalAccountsAvailable } = params;
  const envelopeBudgets = budgets.filter(
    b => !b.deleted && b.budget_id !== INCOME_BUDGET_ID && b.is_envelope
  );
  const negativeBudgets = envelopeBudgets
    .filter(b => b.available < 0)
    .reduce((prev, curr) => prev + curr.available, 0);

  let inBudgets = envelopeBudgets
    .filter(b => b.available > 0)
    .reduce((prev, curr) => prev + curr.available, 0);

  const incomeAvailable = currencyNumber(
    totalAccountsAvailable - inBudgets + negativeBudgets
  );

  return {
    incomeAvailable,
    totalAccountsAvailable,
    inBudgets,
    negativeBudgets,
  }; // should be zero
}

export function calcTotalAvailable(accounts: AccountDocument[]) {
  let total = 0;
  let checkingTotal = 0;
  let debtTotal = 0;
  accounts.map(account => {
    if (!account.include_in_available) return;
    if (account.type === 'credit' || account.type === 'loan') {
      total += account.current || 0;
      debtTotal += account.current || 0;
    } else {
      total += account.available || 0;
      checkingTotal += account.available || 0;
    }
  });
  return {
    total: currencyNumber(total),
    checkingTotal: currencyNumber(checkingTotal),
    debtTotal: currencyNumber(debtTotal),
  };
}

export function getAccountName(account: AccountDocument | undefined) {
  if (!account) return '';
  return account.name_custom || account.name;
}
