import {
  AccountDocument,
  BudgetDocument,
  TransactionDocument,
  TransactionDocumentWithId,
} from '@og-shared/types';
import { Transaction } from 'plaid';

import { getOgCategoryFromPlaidTransaction } from './plaid-categories';
import { isPresent } from './utils';
import { getAccountDisplayNumber } from './account-utils';
import { INCOME_BUDGET_ID, BudgetType } from './consts';

export const sortTransactions = (
  a: Pick<TransactionDocumentWithId, 'date' | 'created_on'>,
  b: Pick<TransactionDocumentWithId, 'date' | 'created_on'>
) => {
  if (a.date < b.date) {
    return 1;
  } else if (a.date > b.date) {
    return -1;
  } else if (a.created_on && b.created_on) {
    return a.created_on > b.created_on ? -1 : 1;
  } else {
    return a.created_on && !b.created_on ? -1 : 0;
  }
};

export function getTransactionCategory(
  transaction: Pick<TransactionDocumentWithId, 'amount' | 'date'>,
  budget: Pick<BudgetDocument, 'budget_id' | 'type'>
) {
  let budgetId = budget.budget_id;
  let childBudgetId = null;
  if (budget.type === BudgetType.INCOME) {
    budgetId = INCOME_BUDGET_ID;
    childBudgetId = budget.budget_id;
  }
  const budgets: TransactionDocumentWithId['budgets'] = childBudgetId
    ? [
        {
          amount: transaction.amount,
          budget_id: budgetId,
          child_budget_id: childBudgetId,
        },
      ]
    : [
        {
          amount: transaction.amount,
          budget_id: budgetId,
        },
      ];
  const categorized: Pick<TransactionDocumentWithId, 'budgets' | 'budget_ids'> =
    {
      budget_ids: getBudgetIdsFromSplitBudgets({
        budgets,
        date: transaction.date,
      }),
      budgets,
    };
  return categorized;
}

export type ReplaceBudgetParams = {
  transaction: Pick<
    TransactionDocumentWithId,
    'transaction_id' | 'date' | 'budgets'
  >;
  oldBudget: Pick<BudgetDocument, 'budget_id' | 'type'>;
  newBudget: Pick<BudgetDocument, 'budget_id' | 'type'>;
};
export type ReplaceBudgetRes = Pick<
  TransactionDocumentWithId,
  'transaction_id' | 'budgets' | 'budget_ids'
>;

export function replaceBudgetInTransaction(params: ReplaceBudgetParams) {
  const { transaction, oldBudget, newBudget } = params;

  let oldBudgetId = oldBudget.budget_id;
  const budgets = transaction.budgets.map(s => {
    if (s.budget_id === oldBudgetId || s.child_budget_id === oldBudgetId) {
      let newBudgetId = newBudget.budget_id;
      let childBudgetId = null;
      if (newBudget.type === BudgetType.INCOME) {
        newBudgetId = INCOME_BUDGET_ID;
        childBudgetId = newBudget.budget_id;
      }
      if (childBudgetId) {
        return {
          amount: s.amount,
          budget_id: newBudgetId,
          child_budget_id: childBudgetId,
        };
      } else {
        return {
          amount: s.amount,
          budget_id: newBudgetId,
        };
      }
    }
    return s;
  });

  const categorized: ReplaceBudgetRes = {
    budget_ids: getBudgetIdsFromSplitBudgets({
      budgets,
      date: transaction.date,
    }),
    budgets,
    transaction_id: transaction.transaction_id,
  };
  return categorized;
}

export function convertPlaidTransactionToOgTransaction(
  plaidTransaction: Transaction,
  budgetId: string | undefined // undefined will require you to add budgets later
) {
  const amount = -convertToCents(plaidTransaction.amount || 0);
  const date = plaidTransaction.authorized_date ?? plaidTransaction.date;
  const ogTransaction: TransactionDocumentWithId = {
    ...plaidTransaction,
    date,
    amount,
    pending_processed: false,
    budgets: budgetId ? [{ budget_id: budgetId, amount }] : [],
    budget_ids: {},
    type: 'plaid',
    deleted: false,
    historical: false,
    og_category: getOgCategoryFromPlaidTransaction(plaidTransaction),
  };
  if (budgetId) {
    ogTransaction.budget_ids = {
      [budgetId]: date,
    };
  }
  return ogTransaction;
}

export function convertToCents(number: number) {
  return Math.round(number * 100);
}

export function getBudgetIdsFromSplitBudgets(
  transaction: Pick<TransactionDocumentWithId, 'budgets' | 'date'>
) {
  const { budgets, date } = transaction;
  // needed for firestore queries
  // complex query on budget page
  const budget_ids: { [budgetId: string]: string } = {};
  for (const budget of budgets) {
    budget_ids[budget.budget_id] = date;
    if (budget.child_budget_id) {
      budget_ids[budget.child_budget_id] = date;
    }
  }
  return budget_ids;
}

export function canDeleteTransaction(
  t: Pick<TransactionDocument, 'transaction_id' | 'type'>
) {
  return t.type !== 'plaid' && t.type !== 'asa' && t.transaction_id;
}

export function isLinkedTransaction(t: Pick<TransactionDocument, 'type'>) {
  return t.type === 'plaid' || t.type === 'asa';
}

export const isBankTransaction = (t: Pick<TransactionDocument, 'type'>) => {
  return t.type === 'plaid' || t.type === 'manual_entry' || t.type === 'asa';
};

export function hasTransactionId(
  t: TransactionDocument
): t is TransactionDocumentWithId {
  return isPresent(t.transaction_id);
}

export function transactionIncludesBudgetCategory(params: {
  transaction: Pick<TransactionDocument, 'budgets'>;
  budgetId: string;
}) {
  const { transaction, budgetId } = params;
  return (
    transaction.budgets.filter(
      s => s.budget_id === budgetId || s.child_budget_id === budgetId
    ).length > 0
  );
}

export function getTransactionBudgets(params: {
  amount: number;
  date: string;
  budget: Pick<BudgetDocument, 'budget_id' | 'type' | 'is_envelope'>;
}) {
  const { amount, budget, date } = params;
  let budgetId = budget.budget_id;
  let childBudgetId = null;
  const parentBudgetId =
    budget.type === BudgetType.INCOME || !budget.is_envelope
      ? INCOME_BUDGET_ID
      : null;

  if (typeof parentBudgetId === 'string') {
    budgetId = parentBudgetId;
    childBudgetId = budget.budget_id;
  }
  return getTransactionBudgetsAndBudgetIds({
    amount,
    date,
    budgetId,
    childBudgetId,
  });
}

export function getTransactionBudgetsAndBudgetIds(params: {
  amount: number;
  date: string;
  budgetId: string;
  childBudgetId: string | null;
}) {
  const { amount, budgetId, childBudgetId, date } = params;

  const splits = childBudgetId
    ? [
        {
          amount,
          budget_id: budgetId,
          child_budget_id: childBudgetId,
        },
      ]
    : [
        {
          amount,
          budget_id: budgetId,
        },
      ];
  return {
    childBudgetId,
    budgetId,
    splits,
    budget_ids: getBudgetIdsFromSplitBudgets({ budgets: splits, date }),
  };
}

export function getTransactionsWithRunningAccountBalance<
  T extends Pick<TransactionDocumentWithId, 'amount'>
>(params: {
  transactions: T[];
  account: Pick<AccountDocument, 'type' | 'current' | 'available'> | undefined;
}) {
  const { transactions, account } = params;
  let runningBalance = getAccountDisplayNumber(account);
  return transactions.map(transaction => {
    const transactionWithRunningBalance: T & {
      runningBalance: number;
    } = {
      ...transaction,
      runningBalance,
    };
    runningBalance = runningBalance - (transaction.amount ?? 0);
    return transactionWithRunningBalance;
  });
}

export function getTransactionsWithRunningBudgetBalance<
  T extends Pick<TransactionDocumentWithId, 'budgets'>
>(params: {
  transactions: T[];
  budget:
    | Pick<BudgetDocument, 'available' | 'budget_id' | 'type' | 'is_envelope'>
    | undefined;
}): (T & {
  runningBalance?: number;
})[] {
  const { transactions, budget } = params;
  if (
    !budget ||
    !budget.is_envelope || // only envelope style budget has a balance
    (budget.type === BudgetType.INCOME && budget.budget_id !== INCOME_BUDGET_ID) // running balance found on INCOME
  ) {
    return transactions;
  }
  let runningBalance = budget.available ?? 0;
  return transactions.map(transaction => {
    const transactionWithRunningBalance: T & {
      runningBalance: number | undefined;
    } = {
      ...transaction,
      runningBalance,
    };
    transaction.budgets
      .filter(
        split =>
          split.budget_id === budget.budget_id ||
          split.child_budget_id === budget.budget_id
      )
      .map(split => {
        runningBalance = runningBalance - split.amount;
      });
    return transactionWithRunningBalance;
  });
}
