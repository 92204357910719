import { loadingController } from '@ionic/core';
import { asaLink, getSignInTokenAndRedirect } from './functions';

export async function asaAuthIfParams(params: {
  [key: string]: string | undefined;
}) {
  const pathAfterGroups = location.href.split('/groups/')[1];
  const pathSegments = pathAfterGroups?.split('/');
  const groupId = pathSegments?.[0];
  const {
    asaconsumerCode,
    bearerToken,
    // asaFintechCode,
    // expirydatefortoken,
    email,
  } = params;

  if (!asaconsumerCode || !bearerToken) return;
  const loading = await loadingController.create({});
  await loading.present();
  try {
    if (groupId) {
      await asaLink({
        group_id: groupId,
        asa_consumer_code: asaconsumerCode,
        bearer_token: bearerToken,
      });
    } else {
      await getSignInTokenAndRedirect({
        partner_id: 'asa',
        authorized_user_id: asaconsumerCode,
        access_token: bearerToken,
        return_url: location.href.split('?')[0],
        email,
      });
    }
  } catch (err) {
    console.error('error logging in with asa', err);
  }
  loading.dismiss();
}
