export function keys<T>(object: T) {
  if (!object) return [];
  return Object.keys(object) as Extract<keyof T, string>[];
}

export function isPresent<T>(val: T): val is NonNullable<T> {
  return val !== undefined && val !== null;
}

export function isKeyPresent<T, K extends keyof T>(
  obj: Partial<T>,
  field: K
): obj is T & Required<Pick<T, K>> & { [P in K]: NonNullable<T[P]> } {
  return field in obj && isPresent(obj[field]);
}

export function exhaustiveCheck(value: never) {
  // this is used to ensure all switch cases return a value.
  // if we add a new value type that the switch should account for, typescript will tell us where the case needs to be added
  return value;
}

export function isValidInput<T>(input: any, options: T[]): input is T {
  return options.includes(input as any);
}

export function getStringSimilarity(s1: string, s2: string) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength == 0) {
    // if empty string - don't match
    return 0;
  }
  return (longerLength - editDistance(longer, shorter)) / longerLength;
}

function editDistance(s1: string, s2: string) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();
  const costs = new Array();
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function getOverlap(s1: string, s2: string) {
  // https://www.geeksforgeeks.org/javascript-program-to-find-longest-common-substring-between-two-strings/
  const str1 = s1;
  const str2 = s2;
  let n = str1.length;
  let m = str2.length;

  let lcs: any = [];
  for (let i = 0; i <= n; i++) {
    lcs[i] = [];
    for (let j = 0; j <= m; j++) {
      lcs[i][j] = 0;
    }
  }

  let result = '';
  let max = 0;
  for (let i = 0; i < n; i++) {
    for (let j = 0; j < m; j++) {
      if (str1[i] === str2[j]) {
        lcs[i + 1][j + 1] = lcs[i][j] + 1;
        if (lcs[i + 1][j + 1] > max) {
          max = lcs[i + 1][j + 1];
          result = str1.substring(i - max + 1, i + 1);
        }
      }
    }
  }
  return result;
}

export function removeDigitsFromEdges(input: string) {
  return input.replace(/^\d+|\d+$/g, '');
}
export function removeDigitsAndSpacesFromEdges(input: string) {
  return input.replace(/^[\d\s]+|[\d\s]+$/g, '');
}

function cleanAndRemoveNumbers(input: string) {
  // Remove leading/trailing spaces and all numbers
  const words = input.split(' ').filter(d => d.match(/\d+/g) === null);
  return words
    .join(' ')
    .replace(/^\s+|\s+$/g, '')
    .replace(/\d+/g, '');
}

function longestCommonSubstring(strings: string[]) {
  if (strings.length === 0) return '';

  // Clean the strings by removing leading/trailing spaces and numbers
  const cleanedStrings = strings.map(s => cleanAndRemoveNumbers(s));

  // Find the shortest string in the array to minimize comparisons
  let shortestString = cleanedStrings.reduce((a, b) =>
    a.length <= b.length ? a : b
  );

  // Function to check if a substring is common to all strings
  const isCommonSubstring = (substr: string) => {
    return strings.every(str =>
      str.toLowerCase().includes(substr.toLowerCase())
    );
  };

  // Iterate over all possible substrings of the shortest string
  for (let len = shortestString.length; len > 0; len--) {
    for (let start = 0; start <= shortestString.length - len; start++) {
      const substr = cleanAndRemoveNumbers(
        shortestString.substring(start, start + len)
      );
      if (isCommonSubstring(substr)) {
        return substr.trim(); // Return the first (longest) common substring found, trimmed
      }
    }
  }

  return '';
}

export function getStringOverlap(strings: string[]) {
  const overlap = longestCommonSubstring(strings);
  // console.log(
  //   `get string overlap from ${strings.length} strings = overlap: ${overlap}`
  // );
  return overlap;
}

export function dictionaryToArray<T>(byId: { [key: string]: T }): T[] {
  return keys(byId)
    .map(k => byId[k])
    .filter(isPresent);
}

export function arrayToDictionary<T>(arr: T[], id: Extract<keyof T, string>) {
  const dictionary: { [id: string]: T } = {};
  arr.map(item => (dictionary[item[id] as any] = item));
  return dictionary;
}

export function getRandomColorNumber() {
  const min = 1;
  const max = 16;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function sortIncomeAndExpenses(a: number, b: number) {
  const aTotal = a;
  const bTotal = b;

  if (aTotal >= 0 && bTotal >= 0) {
    // If income sort by amount descending
    return bTotal - aTotal;
  }
  if (aTotal < 0 && bTotal < 0) {
    // If expense sort by amount ascending
    return aTotal - bTotal;
  }
  // Otherwise, sort income to come before expense
  return aTotal >= 0 ? -1 : 1;
}
