import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  setDate,
  setDay,
  setMonth,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import {
  BudgetDocument,
  BudgetFrequency,
  GroupDocumentWithSubscription,
} from '@og-shared/types';
import { exhaustiveCheck } from './utils';
import { getToday, getTodayString } from './today';

export function getPaidStatusFromDate(
  expiresDateString: string
): Extract<
  GroupDocumentWithSubscription['status'],
  'active' | 'canceled' | 'expiring'
> {
  const todayString = getTodayString();
  const thirtyDaysFromToday = formatDate(addDays(getToday(), 30));
  if (expiresDateString < todayString) {
    return 'canceled';
  }
  if (expiresDateString < thirtyDaysFromToday) {
    return 'expiring';
  }
  return 'active';
}

export function getBudgetFrequencyFromDates(
  dateStrings: string[]
): BudgetFrequency {
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 365, buffer: 4 })) {
    // yearly
    return 1;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 182, buffer: 3 })) {
    // every 6 months
    return 2;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 91, buffer: 3 })) {
    // every 3 months
    return 4;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 61, buffer: 3 })) {
    // every 2 months
    return 6;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 30, buffer: 3 })) {
    // every 1 month
    return 12;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 14, buffer: 0 })) {
    // every two weeks
    return 26;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 15, buffer: 1 })) {
    // twice per month
    return 24;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 7, buffer: 1 })) {
    // every week
    return 52;
  }
  return 0;
}

export function areDatesWithinDays(params: {
  dateStrings: string[];
  daysBetweenDates: number;
  buffer: number; // +/- n days
}) {
  const { dateStrings, daysBetweenDates, buffer } = params;
  const minDays = daysBetweenDates - buffer;
  const maxDays = daysBetweenDates + buffer;

  if (dateStrings.length < 2) return false;
  let count = 0;
  for (var i = 0; i < dateStrings.length - 1; i++) {
    const firstDate = dateStrings[i]!;
    const secondDate = dateStrings[i + 1]!;
    const days = Math.abs(
      differenceInDays(
        getDateAtMidnight(firstDate),
        getDateAtMidnight(secondDate)
      )
    );

    if (minDays <= days && days <= maxDays) {
      count++;
    }
  }
  const ratio = count / (dateStrings.length - 1);
  return ratio > 0.5;
}

export function countFundingDatesBetweenDates(
  budget: Pick<
    BudgetDocument,
    | 'date_start'
    | 'date_end'
    | 'funding_days'
    | 'funding_per_year'
    | 'next_transaction_date'
  > & {
    next_transaction_date: string;
  }
) {
  const {
    date_end,
    date_start,
    funding_days,
    funding_per_year,
    next_transaction_date,
  } = budget;
  const todayString = getTodayString();
  let nextFundingDate = getNextFundingDate(
    { funding_days, funding_per_year },
    todayString
  );
  let startString = todayString;
  if (nextFundingDate && startString < nextFundingDate) {
    startString = nextFundingDate;
  }
  if (date_start && startString < date_start) {
    startString = date_start;
  }

  // if there's an end date before the next-transaction date this means we want it fully funded before the due date
  const endString = date_end ?? next_transaction_date;

  if (endString && startString > endString) {
    // if funding date is after end string - no funding dates available
    return 0;
  }

  // count number of periods between funding date and end date (or transaction date)
  let fundingCount = 1;
  if (funding_per_year === 0) {
    return 1;
  }
  while (true) {
    const newNextDate = getNextDate({
      dateString: nextFundingDate ?? startString,
      perYear: funding_per_year,
      days: funding_days,
    });
    if (newNextDate === nextFundingDate) {
      console.error('error getting next date', {
        dateString: nextFundingDate,
        perYear: funding_per_year,
        days: funding_days,
      });
      break;
    }
    nextFundingDate = newNextDate;
    // console.log('::: nextFundingDate', { fundingCount, nextFundingDate });
    if (nextFundingDate > endString) {
      break;
    }
    fundingCount += 1;
  }
  return fundingCount;
}

function getNoRepeatDate(params: {
  perYear: Extract<BudgetFrequency, 0>;
  days: BudgetDocument['transaction_days'] | BudgetDocument['funding_days'];
}) {
  const { perYear, days = [] } = params;
  if (perYear !== 0) {
    return null;
  }
  if (days.length !== 3) return null;
  const year = days[0];
  const month = days[1];
  const day = days[2];
  return `${year}-${month}-${day}`;
}

export function getNextFundingDate(
  budget: Pick<BudgetDocument, 'funding_days' | 'funding_per_year'>,
  dateString: string
) {
  return budget.funding_per_year === 0
    ? getNoRepeatDate({
        perYear: 0,
        days: budget.funding_days,
      })
    : getNextDate({
        dateString,
        perYear: budget.funding_per_year,
        days: budget.funding_days,
      });
}

export function getNextDate(params: {
  dateString: string;
  perYear: Exclude<BudgetFrequency, 0>;
  days: BudgetDocument['transaction_days'] | BudgetDocument['funding_days'];
}) {
  const { dateString, perYear, days = [] } = params;

  const date = getDateAtMidnight(dateString);
  switch (perYear) {
    case 1: {
      const monthIndex = (days[0] ?? 1) - 1;
      const monthDay = days[1] ?? 1;
      let updatedDate = setDate(setMonth(date, monthIndex), monthDay);
      while (updatedDate <= date) {
        updatedDate = addYears(updatedDate, 1);
      }
      return formatDate(updatedDate);
    }
    case 2: {
      const monthIndex = (days[0] ?? 1) - 1;
      const monthDay = days[1] ?? 1;
      let updatedDate = setDate(setMonth(date, monthIndex), monthDay);
      while (updatedDate <= date) {
        updatedDate = addMonths(updatedDate, 6);
      }
      return formatDate(updatedDate);
    }
    case 4: {
      const monthIndex = (days[0] ?? 1) - 1;
      const monthDay = days[1] ?? 1;
      let updatedDate = setDate(setMonth(date, monthIndex), monthDay);
      while (updatedDate <= date) {
        updatedDate = addMonths(updatedDate, 3);
      }
      return formatDate(updatedDate);
    }
    case 6: {
      const monthIndex = (days[0] ?? 1) - 1;
      const monthDay = days[1] ?? 1;
      let updatedDate = setDate(setMonth(date, monthIndex), monthDay);
      while (updatedDate <= date) {
        updatedDate = addMonths(updatedDate, 2);
      }
      return formatDate(updatedDate);
    }
    case 12: {
      const day = days[0] ?? date.getDate();
      let nextDate = setDate(date, day);

      if (nextDate <= date) {
        nextDate = addMonths(nextDate, 1);
        return formatDate(nextDate);
      }
      if (nextDate.getDate() !== day) {
        // happens in february
        return formatDate(setDate(nextDate, day));
      }
      return formatDate(nextDate);
    }
    case 24: {
      const year = date.getFullYear();
      const month = date.getMonth();
      const sortedDays = days.sort((a, b) => a - b);
      const firstDay = sortedDays[0] ?? 1;
      const secondDay = sortedDays[1] ?? 1 ?? 15;
      const firstDate = new Date(year, month, firstDay);
      const secondDate = new Date(year, month, secondDay);
      const firstDifference = differenceInDays(firstDate, date);
      const secondDifference = differenceInDays(secondDate, date);
      if (date >= firstDate) {
        if (date >= secondDate) {
          return formatDate(addMonths(firstDate, 1));
        } else if (secondDifference < 5 && secondDifference > 0) {
          // within five days - we should probably use next month
          return formatDate(addMonths(firstDate, 1));
        } else {
          return formatDate(secondDate);
        }
      } else if (firstDifference < 5 && firstDifference > 0) {
        // within five days - we should probably use next month
        return formatDate(addMonths(firstDate, 1));
      } else {
        return formatDate(firstDate);
      }
    }
    case 26: {
      let anchorDate = date;
      if (days.length === 3) {
        anchorDate = new Date(days[0]!, days[1]! - 1, days[2]!);
      }

      const weekDay = anchorDate.getDay();

      let updatedDate = setDay(date, weekDay);
      const diff = differenceInCalendarWeeks(updatedDate, anchorDate);
      if (diff % 2 === 1) {
        updatedDate = addWeeks(updatedDate, 1);
      }
      while (updatedDate <= date) {
        updatedDate = addWeeks(updatedDate, 2);
      }
      return formatDate(updatedDate);
    }
    case 52: {
      const weekday = days[0] ?? date.getDay();

      let updatedDate = setDay(date, weekday);
      while (updatedDate <= date) {
        updatedDate = addWeeks(updatedDate, 1);
      }
      return formatDate(updatedDate);
    }
    default: {
      exhaustiveCheck(perYear);
    }
  }
  return formatDate(date);
}

function getPreviousDate(
  dateString: string,
  per_year: Exclude<BudgetDocument['per_year'], 0>,
  transaction_days: BudgetDocument['transaction_days']
) {
  const date = getDateAtMidnight(dateString);
  switch (per_year) {
    case 1: {
      return formatDate(addYears(date, -1));
    }
    case 2: {
      return formatDate(addMonths(date, -6));
    }
    case 4: {
      return formatDate(addMonths(date, -3));
    }
    case 6: {
      return formatDate(addMonths(date, -2));
    }
    case 12: {
      return formatDate(addMonths(date, -1));
    }
    case 24: {
      const year = date.getFullYear();
      const month = date.getMonth();
      const firstDay = transaction_days[0] ?? 1;
      const secondDay = transaction_days[1] ?? 1 ?? 15;
      const firstDate = new Date(year, month, firstDay);
      const secondDate = new Date(year, month, secondDay);

      if (date <= firstDate) {
        if (date <= secondDate) {
          return formatDate(addMonths(firstDate, -1));
        } else {
          return formatDate(secondDate);
        }
      } else {
        return formatDate(firstDate);
      }
    }
    case 26: {
      return formatDate(addWeeks(date, -2));
    }
    case 52: {
      return formatDate(addWeeks(date, -1));
    }
    default: {
      exhaustiveCheck(per_year);
    }
  }
  return formatDate(date);
}

export function getNextBudgetTransactionDateAfterMinDate(params: {
  budget: {
    next_transaction_date: string;
    per_year: Exclude<BudgetDocument['per_year'], 0>;
    transaction_days: BudgetDocument['transaction_days'];
  };
  minDateString: string;
  closestToMinDate?: boolean;
}): string {
  const { budget, minDateString, closestToMinDate = false } = params;

  if (budget.next_transaction_date > minDateString) {
    // already past the date specified
    if (closestToMinDate) {
      return getNextDateClosestToMinDate(params);
    }
    return budget.next_transaction_date;
  }
  const nextDate = getNextDate({
    dateString: budget.next_transaction_date,
    perYear: budget.per_year,
    days: budget.transaction_days,
  });
  if (nextDate == budget.next_transaction_date) {
    console.error('error with this budget', budget);
    return nextDate;
  }

  if (nextDate && nextDate <= minDateString) {
    return getNextBudgetTransactionDateAfterMinDate({
      budget: {
        ...budget,
        next_transaction_date: nextDate,
      },
      minDateString,
    });
  } else {
    return nextDate;
  }
}

function getNextDateClosestToMinDate(params: {
  budget: {
    next_transaction_date: string;
    per_year: Exclude<BudgetDocument['per_year'], 0>;
    transaction_days: BudgetDocument['transaction_days'];
  };
  minDateString: string;
}) {
  const { budget, minDateString } = params;
  let runningDate = budget.next_transaction_date;
  let iterations = 0;
  while (true) {
    // keep going back until we've passed the minDate
    const previousDate = getPreviousDate(
      runningDate,
      budget.per_year,
      budget.transaction_days
    );
    if (previousDate < minDateString) {
      // past min date - use last value
      break;
    }
    runningDate = previousDate;
    if (iterations === 10) {
      console.error('failed getting previous date');
      break;
    }
  }
  return runningDate;
}

export function formatDate(date: Date) {
  const formatted = format(date, 'yyyy-MM-dd');
  return formatted;
}

export function getDateAtMidnight(dateString: string) {
  const dateArray = dateString.split(/\D/);
  return new Date(
    Number(dateArray[0]),
    Number(dateArray[1]) - 1,
    Number(dateArray[2] ?? 1)
  );
}

export function formatDateFromString(dateString: string) {
  if (!dateString) return null;
  const date = getDateAtMidnight(dateString);
  const formatted = format(date, 'M/d/yy');
  return formatted;
}

export function getUnitCount(params: {
  frequency: 1 | 12 | 52 | 365;
  startDate: string;
  endDate: string;
}) {
  const { startDate, endDate, frequency } = params;
  switch (frequency) {
    case 1: {
      const years = differenceInCalendarYears(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return years + 1;
    }
    case 12: {
      const months = differenceInCalendarMonths(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return months + 1;
    }
    case 52: {
      const weeks = differenceInCalendarWeeks(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return weeks + 1;
    }
    case 365: {
      const days = differenceInCalendarDays(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return days + 1;
    }
    default: {
      return exhaustiveCheck(frequency);
    }
  }
}

export function getTrialNoCCStatusFromDate(
  expiresDateString: string | null
): Extract<
  GroupDocumentWithSubscription['status'],
  'trialing_no_cc' | 'canceled' | 'expiring'
> {
  if (!expiresDateString) {
    return 'trialing_no_cc';
  }
  const todayString = getTodayString();
  const fourteenDaysFromToday = formatDate(addDays(getToday(), 14));
  if (expiresDateString < todayString) {
    return 'canceled';
  }
  if (expiresDateString < fourteenDaysFromToday) {
    return 'expiring';
  }
  return 'trialing_no_cc';
}

export function getDateString(date: Date) {
  const dateString = format(date, 'yyyy-MM-dd');
  return dateString;
}

export function getDatesFromSegment(segment: 1 | 2 | 4 | 12) {
  const today = getToday();
  const startDate = formatDate(today);
  switch (segment) {
    case 1:
      return {
        endDate: formatDate(addMonths(today, 14)),
        startDate,
      };
    case 2:
      return {
        endDate: formatDate(addMonths(today, 6)),
        startDate,
      };
    case 4:
      return {
        endDate: formatDate(addMonths(today, 3)),
        startDate,
      };
    case 12:
      return {
        endDate: formatDate(addMonths(today, 1)),
        startDate,
      };
    default:
      exhaustiveCheck(segment);
      return {
        endDate: formatDate(addMonths(today, 14)),
        startDate,
      };
  }
}

export function getDaysFromDateString(
  params:
    | {
        perYear: Exclude<BudgetFrequency, 24>;
        dateString: string;
      }
    | {
        perYear: 24;
        dateString: string[];
      }
): number[] {
  const { perYear, dateString } = params;
  switch (perYear) {
    case 0: {
      return getYearMonthDayFromString(dateString);
    }
    case 1: {
      return getMonthDayFromString(dateString);
    }
    case 2: {
      return getMonthDayFromString(dateString);
    }
    case 4: {
      return getMonthDayFromString(dateString);
    }
    case 6: {
      return getMonthDayFromString(dateString);
    }
    case 12: {
      return [getDayFromString(dateString)];
    }
    case 24: {
      return dateString.map(getDayFromString);
    }
    case 26: {
      return getYearMonthDayFromString(dateString);
    }
    case 52: {
      return [getWeekdayFromString(dateString)];
    }
    default: {
      exhaustiveCheck(perYear);
      return [];
    }
  }
}

function getYearMonthDayFromString(dateString: string) {
  return dateString.split('-').map(Number);
}

function getMonthDayFromString(dateString: string) {
  return getYearMonthDayFromString(dateString).slice(1);
}

function getDayFromString(dateString: string) {
  // 2024-11-07
  return getYearMonthDayFromString(dateString)[2];
}

function getWeekdayFromString(dateString: string) {
  return getDateAtMidnight(dateString).getDay();
}
export type DateSegment =
  | 'this-week'
  | 'this-month'
  | 'last-3-months'
  | 'last-30-days'
  | 'last-90-days'
  | 'last-6-months'
  | 'last-12-months'
  | 'this-year';

export function getDatesBeforeTodayFromSegment(dateSegment: DateSegment) {
  const today = getToday();
  let startDate = formatDate(today);
  let endDate = formatDate(today);
  switch (dateSegment) {
    case 'last-30-days': {
      startDate = formatDate(addDays(today, -30));
      break;
    }
    case 'last-90-days': {
      startDate = formatDate(addDays(today, -90));
      break;
    }
    case 'this-week': {
      startDate = formatDate(startOfWeek(today));
      endDate = formatDate(endOfWeek(today));
      break;
    }
    case 'this-month': {
      startDate = formatDate(startOfMonth(today));
      endDate = formatDate(endOfMonth(today));
      break;
    }
    case 'last-3-months': {
      startDate = formatDate(startOfMonth(addMonths(today, -2)));
      endDate = formatDate(endOfMonth(today));
      break;
    }
    case 'last-6-months': {
      startDate = formatDate(startOfMonth(addMonths(today, -5)));
      endDate = formatDate(endOfMonth(today));
      break;
    }
    case 'last-12-months': {
      startDate = formatDate(startOfMonth(addMonths(today, -11)));
      endDate = formatDate(endOfMonth(today));
      break;
    }
    case 'this-year': {
      startDate = formatDate(startOfYear(today));
      endDate = formatDate(endOfYear(today));
      break;
    }
    default: {
      exhaustiveCheck(dateSegment);
      return {
        startDate,
        endDate,
      };
    }
  }
  return { startDate, endDate };
}
