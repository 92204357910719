import { ModalOptions, modalController } from '@ionic/core';
import { User } from 'firebase/auth';
import { FirebaseCustomClaims } from '@og-shared/types';
import { keys, isPresent } from '@og-shared/utils';

import { checkForNewReleases } from './releases';
import { getUserDocument } from './user';
import {
  signOutFirebase,
  resetFirebaseUI,
  getFirebaseAuth,
  ProviderOptions,
} from './../../global/firebase';
import { identifyAnonymousUser, identifyUser } from '../../services/analytics';
import { firestoreListenToNewReleasesChanges } from '../../services/firestore/releases';
import { authState, resetAuthState, waitForAuthLoaded } from '../auth.store';
import { uiState, resetUiState } from '../ui.store';
import { groupState, resetGroupState } from '../group.store';
import { resetBudgetsState } from '../budgets.store';
import { resetTransactionsState } from '../transactions.store';
import {
  StorageKeys,
  clearStorage,
  getStorageKey,
  setStorageKey,
} from '../../global/storage';
import { sentrySetUser } from '../../services/sentry';
import { canDismiss, getPresentingElement } from '../../global/ion-utils';
import { Components } from '../../components';
import { getAndSetPartnerData } from '../../global/get-partner-data';
import { environment } from '../../global/environment';

export const refreshCustomClaims = async () => {
  await getFirebaseAuth()?.currentUser?.getIdToken(true);
  const user = getFirebaseAuth()?.currentUser;
  if (!user) return;
  signIn(user);
};

export async function signIn(user: User) {
  const idToken = await getFirebaseAuth()?.currentUser?.getIdTokenResult(true);
  if (!idToken) return;
  const customClaims = idToken.claims as unknown as FirebaseCustomClaims;
  authState.stored_group_id = await setSavedGroup(customClaims);
  const groupPermissions = customClaims.groups ?? {};
  const partners_permissions = customClaims.partners ?? {};
  authState.userInfo = {
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL,
    providerId: user.providerId,
    uid: user.uid,
    authenticated: true,
    authLoaded: true,
    groupPermissions,
    partners_permissions,
    admin: customClaims.admin ?? false,
    claimsSet: true,
    joinedGroup: keys(groupPermissions).length > 0 ? true : false,
  };

  firestoreListenToNewReleasesChanges(environment.env);
  identifyUser({
    user,
    jsVersion: uiState.js_version,
    groupDoc: groupState.groupDoc,
  });
  sentrySetUser({ ...user, email: user.email ?? undefined });
  const userDoc = await getUserDocument(user.uid);
  return checkForNewReleases(user.uid, userDoc);
}

export const isSignedOut = async () => {
  if (!authState.userInfo.uid) {
    identifyAnonymousUser(uiState.js_version);
  }
  await resetFirebaseUI();
  resetAuthState();
  resetBudgetsState();
  resetGroupState();
  resetTransactionsState();
  resetUiState();
};

export const signOut = async () => {
  await isSignedOut();
  await signOutFirebase();
  const partnerId = await getStorageKey(StorageKeys.og_partner_id);
  await clearStorage();
  if (!partnerId) return;
  return getAndSetPartnerData(partnerId);
};

async function setSavedGroup(customClaims: FirebaseCustomClaims) {
  const savedGroupId = await getStorageKey(StorageKeys.og_active_group_id);
  if (isMemberOfGroup(customClaims?.groups, savedGroupId)) {
    return savedGroupId;
  } else {
    if (!customClaims || !customClaims.groups) return null;
    const firstGroup = keys(customClaims.groups)[0];
    await setStorageKey(StorageKeys.og_active_group_id, firstGroup);
    return firstGroup;
  }
}

export function isMemberOfGroup(
  groups: FirebaseCustomClaims['groups'],
  groupId: string | null
) {
  if (!groupId) return false;
  if (!groups) return false;
  return isPresent(groups[groupId]);
}

export function isMemberOfPartnerOrganization(
  partners: FirebaseCustomClaims['partners'],
  partnerId: string | null
) {
  if (!partnerId) return false;
  if (!partnerId) return false;
  if (!partners) return false;
  return isPresent(partners[partnerId]);
}

export async function showSignInModal(
  method: 'SIGN_IN' | 'SIGN_UP',
  providerOptions: ProviderOptions = 'all'
) {
  await waitForAuthLoaded();
  if (authState.userInfo.authenticated) return; // already signed in
  const presentingElement = getPresentingElement();
  const componentProps: Components.OgAuthModal = {
    method,
    providerOptions,
  };
  const modalOptions: ModalOptions = {
    presentingElement,
    canDismiss,
    component: 'og-auth-modal',
    backdropDismiss: false,
    componentProps,
  };
  const modal = await modalController.create(modalOptions);
  return modal.present();
}

export function exitApp() {
  window.parent?.postMessage('og-exit-app', '*');
  signOut();
}
