import { createStore } from '@stencil/store';
import { routeInApp } from '../global/route-in-app';
import { PartnerDocument } from '@og-shared/types';

type PartnerState = {
  partner: PartnerDocument | null;
};
const initialState: PartnerState = {
  partner: null,
};
export const {
  state: partnerState,
  onChange: onPartnerStoreChange,
  reset: resetPartnerState,
  dispose: disposePartnerStore,
  on: onPartnerStore,
} = createStore(initialState);

type Path =
  | ''
  | '/shared-data'
  | '/authorized-users'
  | '/authorized-users-changes'
  | '/billing-details';
export function getPartnerPortalUrl(
  partnerId: string | null,
  path: Path,
  id?: string
) {
  if (!partnerId) return '/portal';
  return `/portal/${partnerId}${path ?? ''}${id ?? ''}`;
}

export function changePartner(partnerId: string, path: Path) {
  const partnerPath = getPartnerPortalUrl(partnerId, path);
  resetPartnerState();
  return routeInApp(partnerPath, 'root');
}
