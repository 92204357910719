import { addDays, format } from 'date-fns';
import type {
  AccountsById,
  BudgetDocument,
  BudgetsById,
  TransactionDocument,
  TransactionDocumentWithId,
  BudgetGroup,
  BudgetViewDocument,
  BudgetFrequency,
} from '@og-shared/types';
import {
  formatDate,
  getDateAtMidnight,
  getNextBudgetTransactionDateAfterMinDate,
  exhaustiveCheck,
  keys,
  budgetNotTracked,
  shouldIncludeBudgetTransactionBasedOnStartAndEndDates,
  isBankTransaction,
  IGNORE_BUDGET_ID,
  BudgetType,
  INCOME_BUDGET_ID,
  isPresent,
  fillDays,
  getNextDate,
  getTodayString,
  formatDateFromString,
} from '@og-shared/utils';

import { getAccountName } from './account-utils';
import { translate, translatePlaceholder } from './translate';
import {
  INCOME_VS_EXPENSES_ID,
  SPENDING_BY_ACCOUNT_ID,
  SPENDING_BY_TYPE_ID,
  budgetTypes,
  getBudgetFrequencyName,
  getBudgetFrequencySimpleName,
  getDefaultBudgets,
} from '../services/static-data';
import { getTomorrowDateString } from './date-utils';

export type BudgetGroupItem = {
  name: string;
  total: number;
  color: number;
  icon: string;
  budgetId: string;
  type: BudgetType;
  budgetPlan: number;
  deleted: boolean;
};
export type BudgetGroupItemsData = {
  [budget_group_id: string]: {
    groupName: string;
    items: BudgetGroupItem[];
  };
};

export type SpendingByBudgetId = {
  [budgetId: string]: number;
};
export type SpendingByBudgetView = {
  [budgetViewId: string]: number;
};
export type GetSpendingByBudgetFromTransactionsParams = {
  budgetsById: {
    [budgetId: string]: Pick<BudgetDocument, 'type'>;
  };
  transactions: Pick<TransactionDocument, 'type' | 'budgets'>[];
  budgetView?: {
    groups: Pick<BudgetViewDocument['groups'][0], 'budget_ids' | 'id'>[];
  };
};

export function getSpendingByBudgetFromTransactions(
  params: GetSpendingByBudgetFromTransactionsParams
) {
  const { budgetView, transactions, budgetsById } = params;
  const spendingByBudgetId: {
    [budgetId: string]: number;
  } = {};

  let income = 0;
  let expenses = 0;

  transactions
    .filter(transaction => isBankTransaction(transaction))
    .map(transaction =>
      transaction.budgets.map(split => {
        const budgetId = split.child_budget_id
          ? split.child_budget_id
          : split.budget_id;

        if (!spendingByBudgetId[budgetId]) {
          spendingByBudgetId[budgetId] = 0;
        }
        spendingByBudgetId[budgetId] =
          spendingByBudgetId[budgetId] + split.amount;
        if (!budgetView) return;
        // only continue if a budget view is passed
        const type = budgetsById[budgetId]?.type;
        budgetView.groups.map(group => {
          if (!group.budget_ids.includes(budgetId)) return;
          switch (type) {
            case BudgetType.INCOME:
              return (income += split.amount);
            case BudgetType.BILL:
              return (expenses += split.amount);
            case BudgetType.SAVINGS:
              return (expenses += split.amount);
            case BudgetType.SPENDING:
              return (expenses += split.amount);
            default:
              expenses += split.amount;
              return exhaustiveCheck(type);
          }
        });
      })
    );
  const cashFlow = income + expenses; //expenses will be negative
  return {
    income,
    expenses,
    cashFlow,
    spendingByBudgetId,
    transactions,
  };
}

export function getBudgetViewByBudgetType(sortedBudgetIds: {
  [key in BudgetType]: string[];
}) {
  const { INCOME, SPENDING, SAVINGS, BILL } = sortedBudgetIds;
  const budgetView: BudgetViewDocument = {
    order: 1,
    name: translate('SPENDING_BY_TYPE'),
    id: SPENDING_BY_TYPE_ID,
    groups: [
      {
        budget_ids: INCOME,
        color: budgetTypes.INCOME.color,
        id: budgetTypes.INCOME.value,
        name: translate(budgetTypes.INCOME.translateKey, 1),
      },
      {
        budget_ids: SAVINGS,
        color: budgetTypes.SAVINGS.color,
        id: budgetTypes.SAVINGS.value,
        name: translate(budgetTypes.SAVINGS.translateKey, 2),
      },
      {
        budget_ids: BILL,
        color: budgetTypes.BILL.color,
        id: budgetTypes.BILL.value,
        name: translate(budgetTypes.BILL.translateKey, 2),
      },
      {
        budget_ids: SPENDING,
        color: budgetTypes.SPENDING.color,
        id: budgetTypes.SPENDING.value,
        name: translate(budgetTypes.SPENDING.translateKey, 2),
      },
    ],
  };

  return budgetView;
}

export function getBudgetViewIncomeVsExpenses(sortedBudgetIds: {
  [key in BudgetType]: string[];
}) {
  const { INCOME, SPENDING, SAVINGS, BILL } = sortedBudgetIds;
  const budgetView: BudgetViewDocument = {
    order: 1,
    name: translate('INCOME_VS_EXPENSES'),
    id: INCOME_VS_EXPENSES_ID,
    groups: [
      {
        budget_ids: INCOME,
        color: budgetTypes.INCOME.color,
        id: budgetTypes.INCOME.value,
        name: translate(budgetTypes.INCOME.translateKey, 1),
      },
      {
        budget_ids: [...SAVINGS, ...BILL, ...SPENDING],
        color: 11,
        id: 'EXPENSES',
        name: translate('EXPENSE_S', 2),
      },
    ],
  };

  return budgetView;
}

export function getBudgetViewByAccounts(params: {
  accountsById: AccountsById;
}) {
  const { accountsById } = params;
  const accounts = keys(accountsById).map(key => accountsById[key]);
  const groups: BudgetGroup[] = accounts.map(account => ({
    budget_ids: [],
    color: account.color,
    id: account.account_id,
    name: getAccountName(account),
  }));
  groups.push({
    budget_ids: [],
    color: 4,
    id: 'OG_UNKNOWN',
    name: translate('OG_UNKNOWN'),
  });
  const budgetView: BudgetViewDocument = {
    order: 1,
    name: translatePlaceholder('Spending By Account'),
    id: SPENDING_BY_ACCOUNT_ID,
    groups,
  };
  return budgetView;
}

export function getSpendingByBudgetByAccountFromTransactions(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  transactions: TransactionDocument[];
}) {
  const { accountsById, budgetsById, transactions } = params;
  let income = 0;
  let expenses = 0;
  const spendingByAccount: BudgetGroupItemsData = {
    UNKNOWN: {
      groupName: translate('OG_UNKNOWN'),
      items: [],
    },
  };
  const spendingByAccountIdByBudgetId: {
    [accountId: string]: {
      [budgetId: string]: BudgetGroupItem;
    };
  } = { UNKNOWN: {} };
  keys(accountsById).map(accountId => {
    spendingByAccount[accountId] = {
      groupName: accountsById[accountId].name,
      items: [],
    };
    spendingByAccountIdByBudgetId[accountId] = {};
  });

  transactions
    .filter(transaction => isBankTransaction(transaction))
    .map(transaction =>
      transaction.budgets.map(split => {
        const budgetId = split.child_budget_id
          ? split.child_budget_id
          : split.budget_id;
        const account_id = transaction.account_id;

        const accountKey = account_id || 'UNKNOWN';
        if (!spendingByAccount[accountKey]) {
          spendingByAccount[accountKey] = {
            groupName: translate('OG_UNKNOWN'),
            items: [],
          };
        }
        if (!spendingByAccountIdByBudgetId[accountKey]) {
          spendingByAccountIdByBudgetId[accountKey] = {};
        }
        // spendingByAccount[accountKey].total =
        //   spendingByAccount[accountKey].total + transaction.amount;
        if (!spendingByAccountIdByBudgetId[accountKey][budgetId]) {
          spendingByAccountIdByBudgetId[accountKey][budgetId] = {
            name: budgetsById[budgetId]?.name ?? translate('OG_UNKNOWN'),
            total: 0,
            icon: budgetsById[budgetId]?.icon ?? 'mail',
            color: budgetsById[budgetId]?.color ?? 18,
            budgetId,
            type: budgetsById[budgetId].type,
            budgetPlan: 0,
            deleted: budgetsById[budgetId].deleted,
          };
        }
        spendingByAccountIdByBudgetId[accountKey][budgetId].total =
          spendingByAccountIdByBudgetId[accountKey][budgetId].total +
          split.amount;

        const type = budgetsById[budgetId]?.type;
        switch (type) {
          case BudgetType.INCOME:
            return (income += split.amount);
          case BudgetType.BILL:
            return (expenses += split.amount);
          case BudgetType.SAVINGS:
            return (expenses += split.amount);
          case BudgetType.SPENDING:
            return (expenses += split.amount);
          default:
            expenses += split.amount;
            return exhaustiveCheck(type);
        }
      })
    );
  keys(accountsById).map(accountId => {
    const accountKey = accountId || 'UNKNOWN';
    const accountItems = keys(spendingByAccountIdByBudgetId[accountKey]).map(
      budgetId => spendingByAccountIdByBudgetId[accountKey][budgetId]
    );
    spendingByAccount[accountKey].items = accountItems;
  });

  const cashFlow = income + expenses; //expenses will be negative
  return { spendingByAccount, income, expenses, cashFlow };
}

function getBudgetViewGroups(params: {
  hasUncategorizedTransactions: boolean;
  groups: BudgetViewDocument['groups'];
}) {
  const { hasUncategorizedTransactions, groups } = params;
  const { UNCATEGORIZED } = getDefaultBudgets();

  const hasUncategorizedGroup = groups.find(
    g => g.id === UNCATEGORIZED.budget_id
  );

  if (hasUncategorizedGroup && !hasUncategorizedTransactions) {
    // has group - remove it
    return groups.filter(g => g.id !== UNCATEGORIZED.budget_id);
  } else if (!hasUncategorizedGroup && hasUncategorizedTransactions) {
    // does not have group - add it
    return [
      ...groups,
      {
        budget_ids: [UNCATEGORIZED.budget_id],
        color: UNCATEGORIZED.color,
        id: UNCATEGORIZED.budget_id,
        name: UNCATEGORIZED.name,
      },
    ];
  }
  return groups;
}

export function addOrRemoveUncategorizedGroup(params: {
  transactions: TransactionDocumentWithId[];
  budgetView: BudgetViewDocument;
}) {
  const { transactions, budgetView: budgetViewOriginal } = params;
  const hasUncategorizedTransactions =
    transactions.find(t => t.budget_ids.UNCATEGORIZED > '') !== undefined;

  const budgetViewWithUncategorized: BudgetViewDocument = {
    ...budgetViewOriginal,
    groups: getBudgetViewGroups({
      hasUncategorizedTransactions,
      groups: [...budgetViewOriginal.groups],
    }),
  };
  return { budgetViewWithUncategorized, hasUncategorizedTransactions };
}

export function getExcludedGroup(params: {
  budgetView: BudgetViewDocument;
  allBudgetIds: string[];
  excludedBudgetIds: string[];
}) {
  const { budgetView, allBudgetIds, excludedBudgetIds } = params;

  const allExcludedBudgetIds = getExcludedBudgetIds({
    budgetView,
    allBudgetIds,
    excludedBudgetIds,
  });

  const excludedBudgetsGroup: BudgetGroup = {
    color: 4,
    name: translate('EXCLUDED_CATEGORIES'),
    id: IGNORE_BUDGET_ID,
    budget_ids: allExcludedBudgetIds,
  };

  return excludedBudgetsGroup;
}

export function getExcludedBudgetIds(params: {
  budgetView: Pick<BudgetViewDocument, 'groups'>;
  allBudgetIds: string[];
  excludedBudgetIds: string[];
}) {
  const { budgetView, allBudgetIds, excludedBudgetIds } = params;
  const allInGroups = budgetView.groups.map(g => g.budget_ids).flat();
  const notInGroups = allBudgetIds.filter(
    id => !allInGroups.includes(id) && id !== INCOME_BUDGET_ID
  );
  return [...excludedBudgetIds, ...notInGroups];
}

export function getPlannedSpendingBetweenDates(params: {
  startDate: string;
  endDate: string;
  budget: Pick<
    BudgetDocument,
    | 'date_start'
    | 'date_end'
    | 'budget'
    | 'per_year'
    | 'next_transaction_date'
    | 'budget_id'
    | 'transaction_days'
    | 'type'
  >;
}) {
  const {
    budget: {
      date_start,
      date_end,
      budget: budgetAmount,
      per_year,
      next_transaction_date,
      budget_id,
      transaction_days,
      type,
    },
    startDate,
    endDate,
  } = params;
  let plannedSpending = 0;
  const budget = {
    date_start,
    date_end,
    budget: budgetAmount,
    per_year,
    next_transaction_date,
    budget_id,
    transaction_days,
    type,
  };
  if (budgetNotTracked(budget.budget_id)) return plannedSpending;

  let dateString = startDate;
  // 1. set any missing next transaction dates
  budget.next_transaction_date = getInitialNextTransactionDate(
    startDate,
    budget
  );
  // 3-4. loop day by day to project upcoming transactions
  while (dateString <= endDate) {
    // 4. add projected budget transactions
    if (
      dateString === budget.next_transaction_date &&
      shouldIncludeBudgetTransactionBasedOnStartAndEndDates(budget, dateString)
    ) {
      plannedSpending += budget.budget;
      if (budget.per_year !== 0) {
        budget.next_transaction_date = getNextBudgetTransactionDateAfterMinDate(
          {
            budget: {
              next_transaction_date: budget.next_transaction_date,
              per_year: budget.per_year,
              transaction_days: budget.transaction_days,
            },
            minDateString: dateString,
          }
        );
      }
    }
    const nextDateString = getTomorrowDateString(dateString);
    if (dateString === nextDateString) {
      // prevents infinite loops if our add date code is bad :(
      console.error('failed to add one day', dateString, nextDateString);
      break;
    }
    dateString = nextDateString;
  }
  const inverse = budget.type === BudgetType.INCOME ? 1 : -1;
  return plannedSpending * inverse;
}

function getInitialNextTransactionDate(
  dateString: string,
  budget: Pick<
    BudgetDocument,
    'per_year' | 'next_transaction_date' | 'budget_id' | 'transaction_days'
  >
) {
  if (budget.per_year === 0) return budget.next_transaction_date;
  if (budget.budget_id === INCOME_BUDGET_ID) return null;
  const minDateString = formatDate(addDays(getDateAtMidnight(dateString), -1));
  // minus one day to inclusive of the min date ^^
  return getNextBudgetTransactionDateAfterMinDate({
    budget: {
      next_transaction_date: budget.next_transaction_date ?? dateString,
      transaction_days: budget.transaction_days,
      per_year: budget.per_year,
    },
    minDateString,
  });
}

export function renderBudgetRepeatText(
  budget: Pick<
    BudgetDocument,
    'per_year' | 'next_transaction_date' | 'transaction_days'
  >,
  simple?: boolean
) {
  const frequency = budget.per_year;
  if (frequency === 0 && !budget.next_transaction_date) {
    return translate('NONE');
  }
  const repeatText = simple
    ? getBudgetFrequencySimpleName(frequency)
    : getBudgetFrequencyName(frequency);
  const dateString = getNextDateString({
    frequency: budget.per_year,
    date: budget.next_transaction_date ?? null,
    days: budget.transaction_days,
  });
  return `${repeatText} ${dateString}`;
}

export function getNextDateString(params: {
  frequency: BudgetFrequency;
  date: string | null;
  days: number[];
}) {
  const { frequency, date, days = [] } = params;
  let nextDate = date;
  if (!nextDate && frequency !== 0) {
    nextDate = getNextDate({
      dateString: getTodayString(),
      days: days,
      perYear: frequency,
    });
  }
  switch (frequency) {
    case 0: {
      return `(${nextDate ? formatDateFromString(nextDate) : '?'})`;
    }
    case 1: {
      return `(${nextDate ? formatDateFromString(nextDate) : '?'})`;
    }
    case 2: {
      return `(${nextDate ? formatDateFromString(nextDate) : '?'})`;
    }
    case 4: {
      return `(${nextDate ? formatDateFromString(nextDate) : '?'})`;
    }
    case 6: {
      return `(${nextDate ? formatDateFromString(nextDate) : '?'})`;
    }
    case 12: {
      return `(${days[0] ? getDateStringFromNumber(days[0]) : '?'})`;
    }
    case 24: {
      return `(${
        days.length > 0 ? days.map(getDateStringFromNumber).join(', ') : '?'
      })`;
    }
    case 26: {
      return `(${nextDate ? formatDateFromString(nextDate) : '?'})`;
    }
    case 52: {
      const weekday = days?.[0];
      return `(${isPresent(weekday) ? translate(fillDays[weekday]) : '?'})`;
    }
    // case 365: {
    //   const days = differenceInCalendarDays(
    //     getDateAtMidnight(endDate),
    //     getDateAtMidnight(startDate)
    //   );
    //   return days + 1;
    // }
    default: {
      return exhaustiveCheck(frequency);
    }
  }
}

function getDateStringFromNumber(date?: number) {
  if (!date) return '';
  return format(new Date(0, 0, date), 'do');
}
