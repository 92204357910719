import { createStore } from '@stencil/store';
import { UserDocument } from '@og-shared/types';
import { getStorageKey, StorageKeys } from '../global/storage';

const initialState: AuthState = {
  stored_group_id: null,
  home_page: null,
  userInfo: {
    displayName: null,
    email: null,
    photoURL: null,
    providerId: null,
    uid: null,
    admin: false,
    authenticated: false,
    authLoaded: false,
    claimsSet: false,
    groupPermissions: {},
    joinedGroup: false,
    partners_permissions: {},
  },
  userDoc: undefined,
};
export const {
  state: authState,
  onChange: onAuthStoreChange,
  reset: _resetAuthState,
  dispose: disposeAuthStore,
  on: onAuthStore,
} = createStore<AuthState>(initialState);

export function resetAuthState() {
  _resetAuthState();
  authState.userInfo = {
    ...authState.userInfo,
    authLoaded: true,
  };
}

export function waitForAuthLoaded() {
  if (authState.userInfo.authLoaded) return true;
  return new Promise(resolve => {
    if (authState.userInfo.authLoaded) {
      resolve(authState.userInfo.authLoaded);
    } else {
      const unsubscribe = onAuthStoreChange('userInfo', userInfo => {
        if (!userInfo.authLoaded) return;
        resolve(userInfo.authLoaded);
        unsubscribe();
      });
    }
  });
}

export function waitForAuthenticated() {
  if (authState.userInfo.authenticated) return true;
  return new Promise(resolve => {
    if (authState.userInfo.authenticated) {
      resolve(authState.userInfo.authenticated);
    } else {
      const unsubscribe = onAuthStoreChange('userInfo', userInfo => {
        if (!userInfo.authenticated) return;
        resolve(userInfo.authenticated);
        unsubscribe();
      });
    }
  });
}

export async function loadStoredAuthState() {
  const home_page = await getStorageKey(StorageKeys.og_home_page);
  const stored_group_id = await getStorageKey(StorageKeys.og_active_group_id);

  authState.home_page = home_page;
  authState.stored_group_id = stored_group_id;
}

export function isCurrentUser(uid: string) {
  if (!uid) return false;
  return uid === authState?.userInfo?.uid;
}

export function setUserDoc(userDoc: UserDocument) {
  authState.userDoc = userDoc;
}
