import { BudgetViewDocument } from '@og-shared/types';
import { createStore } from '@stencil/store';
import { arrayToDictionary, dictionaryToArray } from '@og-shared/utils';
import { firestoreGetBudgetViews } from '../services/firestore/budget-views';
import { SPENDING_BY_TYPE_ID } from '../services/static-data';

type BudgetViewState = {
  budgetViewsById: {
    [id: string]: BudgetViewDocument;
  };
  activeBudgetView: BudgetViewDocument | null;
};
const initialState: BudgetViewState = {
  activeBudgetView: null,
  budgetViewsById: {},
};
export const {
  state: budgetViewState,
  onChange: onBudgetViewStoreChange,
  reset: resetBudgetViewState,
  dispose: disposeBudgetViewStore,
  on: onBudgetViewStore,
} = createStore<BudgetViewState>(initialState);

export function setActiveBudgetView(activeBudgetView: BudgetViewDocument) {
  budgetViewState.activeBudgetView = activeBudgetView;
}

export const getBudgetViewsAndSetActiveView = async (
  groupId: string,
  activeBudgetViewId: string
) => {
  const savedBudgetViews = await firestoreGetBudgetViews(groupId);
  setBudgetViewsById(savedBudgetViews);
  const savedBudgetView = budgetViewState.budgetViewsById[activeBudgetViewId];
  if (savedBudgetView) {
    setActiveBudgetView(savedBudgetView);
    return;
  }
  // fallback if saved budget view id isn't valid
  setActiveBudgetView(budgetViewState.budgetViewsById[SPENDING_BY_TYPE_ID]);
};

export function setBudgetViewsById(budgetView: BudgetViewDocument[]) {
  budgetViewState.budgetViewsById = {
    ...budgetViewState.budgetViewsById,
    ...arrayToDictionary(budgetView, 'id'),
  };
  const updateActive = budgetView.find(
    v => v.id === budgetViewState.activeBudgetView?.id
  );
  if (updateActive) {
    setActiveBudgetView(updateActive);
  }
}

export function setBudgetViewById(budgetView: BudgetViewDocument) {
  budgetViewState.budgetViewsById[budgetView.id] = budgetView;
  if (budgetViewState.activeBudgetView?.id !== budgetView.id) return;
  setActiveBudgetView(budgetView);
}

export function removeBudgetViewById(id: string) {
  const { [id]: remove, ...rest } = budgetViewState.budgetViewsById;
  budgetViewState.budgetViewsById = rest;
  if (budgetViewState.activeBudgetView?.id !== id) return;
  //fallback when budget view is deleted
  setActiveBudgetView(budgetViewState.budgetViewsById[SPENDING_BY_TYPE_ID]);
}

export function getStoredBudgetViews() {
  return dictionaryToArray(budgetViewState.budgetViewsById).sort(
    (a, b) => a.order - b.order
  );
}
