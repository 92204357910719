import { OptionalField, TransactionRule } from '@og-shared/types';
import { createStore } from '@stencil/store';
import {
  arrayToDictionary,
  dictionaryToArray,
  isKeyPresent,
} from '@og-shared/utils';

import {
  firestoreCreateRule,
  firestoreDeleteRule,
  firestoreGetRules,
  firestoreUpdateRule,
} from '../services/firestore/rules';
import { loadingController } from '@ionic/core';

type RulesState = {
  rulesLoaded: boolean;
  rulesById: {
    [id: string]: TransactionRule;
  };
};
const initialState: RulesState = {
  rulesLoaded: false,
  rulesById: {},
};
export const {
  state: rulesState,
  onChange: onRulesStoreChange,
  reset: resetRulesState,
  dispose: disposeRulesStore,
  on: onRulesStore,
} = createStore<RulesState>(initialState);

export const getRulesAndSetStore = async (groupId: string) => {
  const savedRules = await firestoreGetRules(groupId);
  setRulesById(savedRules);
};

export function setRulesById(rule: TransactionRule[]) {
  rulesState.rulesById = {
    ...rulesState.rulesById,
    ...arrayToDictionary(rule, 'rule_id'),
  };
  rulesState.rulesLoaded = true;
}

export function setRuleById(rule: TransactionRule) {
  const rulesById = {
    ...rulesState.rulesById,
    [rule.rule_id]: rule,
  };
  rulesState.rulesById = rulesById;
}

export function removeRuleById(id: string) {
  const { [id]: remove, ...rest } = rulesState.rulesById;
  rulesState.rulesById = rest;
}

export function getSortedRules(rulesById: { [id: string]: TransactionRule }) {
  return dictionaryToArray(rulesById).sort((a, b) => a.order - b.order);
}

export function findRulesForBudgetId(
  rulesById: { [id: string]: TransactionRule },
  budgetId?: string
) {
  return getSortedRules(rulesById).filter(
    r => r.actions.filter(a => a.budget_id === budgetId).length > 0
  );
}

export async function saveRule(
  groupId: string,
  newRule: OptionalField<TransactionRule, 'rule_id' | 'order'>
) {
  const loading = await loadingController.create({});
  await loading.present();
  if (isKeyPresent(newRule, 'rule_id')) {
    await firestoreUpdateRule(groupId, newRule);
    setRuleById(newRule);
  } else {
    const rule = await firestoreCreateRule(groupId, {
      ...newRule,
      order: dictionaryToArray(rulesState.rulesById).length,
    });
    setRuleById(rule);
  }
  await loading.dismiss();
}

export async function deleteRule(groupId: string, ruleId: string) {
  const loading = await loadingController.create({});
  await loading.present();
  await firestoreDeleteRule(groupId, ruleId);
  removeRuleById(ruleId);
  await loading.dismiss();
}
